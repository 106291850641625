.skills {
    background-color: #f0f0f0;
    color: rgb(0, 0, 0);
}

.tech-icons {
    font-size: 4.5em !important;
    margin: 15px !important;
    padding: 10px !important;
    opacity: 0.93 !important;
    border: 1.7px solid #f0f0f0 !important;
    vertical-align: middle !important;
    text-align: center !important;
    border-radius: 5px !important;
    display: table !important;
    box-shadow: 4px 5px 4px 3px rgba(143, 143, 143, 0.137) !important;
    overflow: hidden !important;
    transition: all 0.4s ease 0s !important;
}

.tech-icons:hover {
    transform: scale(1.05) !important;
    overflow: hidden !important;
    border: 2.2px solid #f0f0ad !important;
}