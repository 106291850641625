.mea {
    width: 180%;
    height: 45%;
}

.study {
    margin-top: 6px;
    margin-left: 0px;
    height: 300px !important;
    width: 550px !important;
    border: none !important;

}

.study .mea {
    height: 110%;
}

@media (max-width: 769px) {
    .mea {
        height: 110%;
        width: 55%;
    }

    .study {
        margin-top: 5px;
        margin-left: -8px;
        height: 300px !important;
        width: 325px !important;
        border: none !important;
    }
}