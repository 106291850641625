.home-about-body {
    padding-top: 50px;
    font-size: 1.2em !important;
    text-align: left;
}

.home-about-description {
    padding-top: 50px !important;
}

.purple {
    color: rgb(0, 0, 0);
}

.des.about-page {
    display: flex;
    padding: 10px;
    align-items: center;
    flex-direction: row-reverse;
}

/* Text Section on the Left */
.text-zone {
    flex: 7;
    max-width: 70%;
    padding-top: 40px;
}

.stage-cube-cont {
    flex: 3;
    max-width: 30%;
    position: sticky;
}

.cubespinner {
    animation-name: spincube;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
    animation-duration: 12s;
    transform-style: preserve-3d;
    transform-origin: 100px 100px 0;
    margin-left: calc(50% - 100px);
}

.cubespinner div {
    position: fixed;
    width: 200px;
    height: 200px;
    border: 1px solid #ccc;
    background: rgba(255, 255, 255, 0.4);
    text-align: center;
    font-size: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 0 20px 0px lightyellow;
}

.cubespinner .face1 {
    transform: translateZ(100px);
    color: #dd0031;
}

.cubespinner .face2 {
    transform: rotateY(90deg) translateZ(100px);
    color: #f06529;
}

.cubespinner .face3 {
    transform: rotateY(90deg) rotateX(90deg) translateZ(100px);
    color: #28a4d9;
}

.cubespinner .face4 {
    transform: rotateY(180deg) rotateZ(90deg) translateZ(100px);
    color: #5ed4f4;
}

.cubespinner .face5 {
    transform: rotateY(-90deg) rotateZ(90deg) translateZ(100px);
    color: #efd81d;
}

.cubespinner .face6 {
    transform: rotateX(-90deg) translateZ(100px);
    color: #ec4d28;
}

@keyframes spincube {

    from,
    to {
        transform: rotateX(0deg) rotateY(0deg) rotateZ(0deg);
    }

    16% {
        transform: rotateY(-90deg);
    }

    33% {
        transform: rotateY(-90deg) rotateZ(90deg);
    }

    50% {
        transform: rotateY(-180deg) rotateZ(90deg);
    }

    66% {
        transform: rotateY(-270deg) rotateX(90deg);
    }

    83% {
        transform: rotateX(90deg);
    }
}

@media (max-width:769px) {

    .des.about-page {
        display: flex;
        padding: 10px;
        align-items: center;
        flex-direction: column;
        align-content: flex-start;
        flex-wrap: wrap;
        justify-content: space-evenly;
    }

    .text-zone {
        flex: 7 1;
        max-width: 100%;
        padding-top: 40px;
    }

    .stage-cube-cont {
        flex: 3 1;
        position: sticky;
        padding-top: 30px;
    }

    .home-about-body {
        padding-top: 20px;
        font-size: 1.0em !important;
        text-align: left;
    }
}