.project-card-view {
    box-shadow: 0 4px 5px 3px rgba(0, 0, 0, 0.459) !important;
    color: rgb(255, 255, 255) !important;
    background-color: transparent !important;
    opacity: 0.9 !important;
    transition: all 0.5s ease 0s !important;
    height: 100% !important;
    width: 300px !important;
}

.project-card-view:hover {
    transform: scale(1.02) !important;
    overflow: hidden !important;
    box-shadow: 0 4px 4px 5px rgba(206, 202, 142, 0.561) !important;
}

.cardimage {
    height: 200px !important;
}

.linkbutton {
    background-color: #ffffff !important;
    /* Change to a green shade */
    color: rgb(0, 0, 0) !important;
    /* Text color */
    border: none !important;
    /* Remove default border */
    font-weight: bold !important;
    /* Make the text bold */
    padding: 5px 10px !important;
    /* Add padding for better button size */
    border-radius: 5px !important;
    /* Rounded corners */
    transition: background-color 0.3s ease, transform 0.3s ease !important;
    /* Smooth hover effect */
    text-decoration: none !important;

}

.linkbutton:hover {
    background-color: #b6b6b6;
    /* Slightly darker green on hover */
    transform: scale(1.05);
    /* Slightly enlarge on hover */
    color: white !important;
    /* Ensure text color stays consistent */
}


.probutton {
    background-color: black !important;
}

.probutton:hover {
    background-color: rgb(46, 43, 43) !important;
}