.project-section {
    background-color: #7f7f5e;
    height: 100%;
    display: flex;
    color: #ffffff;
    flex-direction: column;
    position: relative !important;
    padding-top: 150px !important;
    padding-bottom: 30px !important;
    background-image: var(--section-background-color) !important;
}

.project-card {
    padding-top: 50px !important;
    padding-bottom: 50px !important;
    padding-left: 25px !important;
    padding-right: 25px !important;
    height: auto !important;
}

.project-heading {
    color: rgb(255, 255, 255) !important;
    font-size: 4.3em !important;
    font-weight: 500 !important;
    padding-top: 10px !important;
}

.subheading {
    color: white;
    font-size: 1.5em !important;
}