.homepage {
    background: linear-gradient(100deg, #ffffff 50%, #7f7f5e 50%);
    display: flex;
    height: 100vh;
}

.first {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding-top: 170px;
    align-items: flex-start;
    padding-left: 25px;
}

.second {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: flex-end;
}

.pic {
    width: 500px;
}

.hi {
    font-size: 28px;
    font-weight: 500;
    margin: 0;
}

.name {
    font-size: 45px;
    font-weight: 800;
    font-family: sans-serif;
    margin: 10px 0 0 0;
}

.desc {
    font-style: italic;
    width: 503px;
    color: #7b6e6e;
}

@media (max-width:769px) {
    .homepage {
        background: linear-gradient(-32deg, #7f7f5e 53%, #ffffff 50%);
        display: flex;
        height: 100vh;
    }

    .first {
        align-items: flex-start;
        display: flex;
        flex: 1 1;
        flex-direction: column;
        justify-content: flex-start;
        padding-left: 15px;
        padding-top: 40px;
    }

    .hi {
        font-size: 17px;
        font-weight: 500;
        margin: 0;
    }

    .name {
        font-size: 25px;
        font-weight: 800;
        margin: 10px 0 0;
    }

    .role {
        font-family: sans-serif;
        color: rgb(105 104 104);
        font-stretch: condensed;
        font-style: normal;
        font-weight: lighter;
        margin: 0;
        font-size: x-large;
    }

    .desc {
        color: #7b6e6e;
        font-size: small;
        font-style: italic;
        width: 310px;
    }

    .pic {
        bottom: 0;
        position: absolute;
        width: 325px;
    }

    .second {
        flex: 1 1;
        display: flex;
        flex-direction: column;
    }
}